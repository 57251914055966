<template>
  <div id="parkingLotList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车场管理</el-breadcrumb-item>
      <el-breadcrumb-item>停车场列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              clearable
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">主体类型:</span>
            <el-select
              v-model="filtrate.subjectType"
              placeholder="请选择主体类型"
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_subjectType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">商圈:</span>
            <el-select
              v-model="filtrate.businessCircleId"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in businessList"
                :key="item.businessCircleId"
                :label="item.businessCircleName"
                :value="item.businessCircleId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询
            </el-button>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addParkingLot"
              type="primary"
              @click="addParkingLot"
              v-if="buttonList.includes('parkingLotList-addParkingLot')"
              ><i class="iconfont icon-jiahao"></i> 新建停车场
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
        row-key="parkingLotId"
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :key="table.loading"
      >
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="parkingLotAddress"
          label="停车场地址"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="combination"
          label="剩余车位/总车位(产权/人防)"
          align="center"
          width="200px"
        >
        </el-table-column>
        <el-table-column
          prop="parkingLotType"
          label="停车场类型"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="subjectType" label="主体类型" align="center">
        </el-table-column>
        <el-table-column
          prop="passagewayNum"
          label="出/入口数量"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="goToDetail(scope.row.parkingLotId)"
              v-if="buttonList.includes('parkingLotList-detail')"
              >查看详情
            </el-button>
            <el-button
              type="text"
              @click="inFieldCode(scope.row.parkingLotId)"
              v-if="buttonList.includes('parkingLotList-InFieldCode')"
              >场内码下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <add-parking-lot-dialog
      ref="addParkingLotDialog"
      :getList="getList"
      :businessList="businessList"
    />
  </div>
</template>
<script>
import QRCode from "qrcode";
export default {
  components: {
    addParkingLotDialog: () => import("./addParkingLotDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        subjectType: null,
        businessCircleId: null,
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  computed: {
    businessList() {
      return this.$store.state.select.businessList;
    },
  },
  methods: {
    async inFieldCode(parkingLotId) {
      try {
        const imgUrl = await QRCode.toDataURL(
          `${process.env.VUE_APP_PARKINGLOT_QR_CODE}?id=$${parkingLotId}`,
          { width: 1000 }
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = imgUrl;
        link.setAttribute("download", "场内码");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.$message.error("场内码生成失败");
      }
    },
    async load(tree, treeNode, resolve) {
      let res = await this.$http.post("/parking/list", {
        parkingParentId: tree.parkingLotId,
      });
      if (res.code === 0) {
        for (let item of res.data.list) {
          item.hasChildren = item.hasChildren === "是";
          item.parkingLotType = this.parkingLotType_Format(item.parkingLotType);
          item.passagewayNum = `${item.exitusNum ? item.exitusNum : 0} - ${
            item.entranceNum ? item.entranceNum : 0
          }`;
          item.combination = `${item.idleLots}/${item.totalLots}(${item.property}/${item.civilDefense})`;
        }
        resolve(res.data.list);
      } else {
        this.$message.error(res.msg);
      }
    },
    addParkingLot() {
      this.$refs.addParkingLotDialog.show = true;
    },
    reset() {
      this.filtrate.keyword = "";
      this.filtrate.subjectType = null;
      this.filtrate.businessCircleId = null;
      this.getList(1);
    },
    goToDetail(parkingLotId) {
      this.$router.push({
        name: "parkingLotDetail",
        params: { id: parkingLotId },
      });
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/list", {
          keyword: this.filtrate.keyword,
          subjectType: this.filtrate.subjectType,
          businessCircleId: this.filtrate.businessCircleId,
          pageNum: page,
          pageSize: this.table.pageSize,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.hasChildren = item.hasChildren === "是";
            item.parkingLotType = this.parkingLotType_Format(
              item.parkingLotType
            );
            item.passagewayNum = `${item.exitusNum ? item.exitusNum : 0} - ${
              item.entranceNum ? item.entranceNum : 0
            }`;
            item.combination = `${item.idleLots}/${item.totalLots}(${item.property}/${item.civilDefense})`;
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#parkingLotList {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addParkingLot {
            background-color: #ff9747;
            border-color: #ff9747;

            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
